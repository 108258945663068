.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    background-color: #f7faff;
    width: var(--sidebar-width);
    margin-left: 0px;
}

@include media-breakpoint-down(sm) {
    .navbar-brand {
        padding-top: 0;
        background-color: transparent;
        width: auto;
        border-right: none;
    }
}

.navbar-nav>li>a,
.navbar-nav>li>.nav-item {
    color: white;
}

.navbar-nav>li>a:focus,
.navbar-nav>li>a:hover,
.navbar-nav>li>.nav-item:focus,
.navbar-nav>li>.nav-item:hover {
    color: var(--branding);
}

.navbar-nav>li>a>em,
.navbar-nav>li>.nav-item>em {
    color: inherits;
}

.navbar-nav>li.active,
.navbar-nav>li.active>a,
.navbar-nav>li.active>.nav-item,
.navbar-nav>li.active .navbar-nav,
.navbar-nav>li.open,
.navbar-nav>li.open>a,
.navbar-nav>li.open>.nav-item,
.navbar-nav>li.open .navbar-nav {
    background-color: #f2f7ff;
    color: var(--branding);
}

.navbar-toggler-icon {
    display: none;
}
a:hover {
    text-decoration: none;
}

*:focus {
    outline: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}



.card-size {
    width: 240px;
    margin-inline-end: 1rem;
}



.d-i {
    display: inline !important
}



.text-align-start {
    text-align: start;
}

/* Bootstrap 4 text input with search icon */

.has-search .form-control {
    padding-inline-end: 2.375rem;

}

.form-control {
    font-size: 12px;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 1.775rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.card-subtitle {
    font-size: 10px;
    color: #888888
}



.modal-footer {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    background-color: var(--light-gray5);
    border-top: 1px solid var(--border);
}

.modal-sm .modal-footer {
    padding: 0;
}

.logo-selector-box {
    width: 140px;
    height: 140px;
    border-radius: 5px;
    // background-color: #e9eef1;
    font-size: 10px;
    margin-top: 30px;

    color: #737373;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    input[type="file"] {
        display: none;
    }

    img {
        width: 54px;
        height: 43px;
    }
}

.bd-callout-info {
    border-left-color: #5BC0DE !important;
}

.bd-callout {
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid #eee;
    border-left-width: 0.25rem;
    border-radius: 0.25rem;
}

.min-height-auto {
    min-height: auto !important;
}

.center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .center-content {
        display: flex;
        flex-direction: column;
        border-radius: 7px;
        min-height: 600px;
        background-color: white;
        // -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
        // box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
    }
}

.empty-list-placeholder {

    align-items: center;
    justify-content: center;


}

.empty-list-content {
    // width: 50%;
    // height: 142px;
    font-size: 14px;
    font-weight: 500;
    color: var(--sidebar-color);
    background-color: var(--light-gray) !important;
    box-shadow: unset !important;
    padding: 20px;
    border-radius: 4px;
}


// .fa-ellipsis-v {
//     color: #c4c4c4;
// }
.dropdown-item {
    font-size: 13px;
    font-weight: 500;

    color: rgb(75, 75, 75);
}

.dropdown-item:disabled {
    color: #778588;
}

.dropdown-item:focus {
    color: rgb(75, 75, 75);
}



.tether-element {
    z-index: 116;
}

.dayWrapper button {
    opacity: 1 !important;
}

.h-100 {
    height: 100% !important;
}

.react_times_button {
    display: inline-block;
    box-shadow: none !important;
    border-color: #b3b3b37a;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px !important;
    // -webkit-box-sizing: inherit;
    // height: 53px;
    -webkit-box-shadow: none !important;
    height: 35px;
}

.preview_container svg {
    margin-inline-start: -20px;
}

.time_picker_preview:not(.disabled):active,
.time_picker_preview:not(.disabled).active {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.react_times_button .wrapper {
    width: 160px;
    overflow: hidden;
}

.add-manually-time-modal.modal-lg {
    max-width: 545px !important;
}

.input-fixer {
    padding: 1px !important;
    height: 24px;
    line-height: 24px;
}

.max-content {
    width: max-content;
}

.modal-header {
    border: none;
}

.modal-close-button {
    float: right;
    cursor: pointer;
}

.action-box,
.modal-footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    // margin-top: 10px;
}

.modal-title {
    margin-bottom: 0;
    font-size: 16px !important;
    width: 100%;
}

.Toastify__toast-container {
    z-index: 200000;
    opacity: 1 !important;
}

.readonly-label {
    background-color: #e9eef1;
}



.background-hover:hover {
    background-color: whitesmoke;
}

.item-shadow-border-danger {
    box-shadow: 0px 0px 5px 1px #E76A6E88 !important;
    -webkit-box-shadow: 0px 0px 5px 1px #E76A6E88 !important;
}

.item-shadow-border-danger:hover {
    box-shadow: 0px 0px 5px 1px #E76A6E !important;
    -webkit-box-shadow: 0px 0px 5px 1px #E76A6E !important;
}

.message {
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px;
    width: 100%
}

.message-success {
    border-color: chartreuse
}

.project-settings-tabs {
    .nav-link {
        text-align: center;
    }
}

.top-notif-message {
    background-color: #FBD065;
    height: $top-notif-message-height;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-weight: bold;
    position: relative;
    z-index: 1500;
}

.w-full {
    width: 100%;
}

.timezone-select {
    width: 100%
}







* {
    font-family: var(--font-family-default-latin);
}

.right-to-left *:not(i) {
    font-family: var(--font-family-default-rt);
}


.organization-selector {
    color: var(--organization-selector-color);
    background-color: var(--organization-selector-bg);
    box-shadow: var(--organization-selector-shadow);
    height: var(--heading-height);
    display: flex;
    cursor: pointer;
    width: var(--sidebar-width-without-app-menu);
}

.bp4-popover2-transition-container {
    z-index: 116;
}

.bp4-popover2-transition-container {
    background-color: white;
}

.dropdown-item:active {
    color: white;
}

.btn-save {
    width: 150px;
}


.between-lines-container {
    text-align: center;

    .line {
        height: 1px;
        background-color: var(--primary-disabled-bg);
    }

    .text {
        position: relative;
        top: 9px;
        background: #fff;
        display: inline-block;
        padding: 0 5px;
        color: var(--primary-disabled-bg);
    }
}

.no-bottom-margin {
    margin-bottom: 0px !important;
}

.rounded-50 {
    border-radius: 50%;
}

.logo-selector-botton {
    color: var(--branding);
    border-color: var(--branding);
    cursor: pointer;
    font-weight: 600;
}

.logo-selector-default-photo {
    background-color: var(--light-gray3);
    width: 90px;
    height: 90px;
}

.form-label {
    color: var(--gray1);
    font-size: 12px;
}

.label {
    margin-bottom: 0.2rem;
}

.form-control {
    font-size: 12px;
    height: 32px;
}

.ReactTags__suggestions {
    z-index: 1;
}

.select-control {
    font-size: 12px;
}

.product-logo {
    height: 50px;
    width: 200px;
}

.text-align-end {
    text-align: end;
}


.text-align-center {
    text-align: center;
}

.text-align-justify {
    text-align: justify;
}

.react-datepicker__header {
    background-color: white !important;
    padding: 0 !important;
}

.react-datepicker__day--highlighted {
    background-color: transparent !important;
    background-image: url('../../icons/circle-icon.svg');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
    background-size: 8px;
    // border: 0.5px solid var(--primary);
    color: black !important;
}

.react-datepicker__day--highlighted:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0 !important;
}

.date-picker-comment-red-cirles {
    font-size: smaller;
}

.sb-avatar--src,
.sb-avatar--text {
    border: none !important
}

#ratePrepend {
    font-size: 12px;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: inherit;
    color: #4b4b4b
}

.dropdown-item.active.text-danger,
.dropdown-item.text-danger:active {
    color: var(--red) !important
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: rgba(95, 107, 124, .6) !important;
}

.invoice-preview-container {
    display: flex;
    justify-content: center;
}

.invoice-preview {
    color: black !important;
    font-size: 13px;
    font-weight: bold;
    width: 100%;
    max-width: 800px;
}

.textAlignRight {
    text-align: right;
}



.d-i-sm {
    display: inline !important;
}

.t-m20 {
    top: -20px !important;
}

@media (max-width: 576px) {

    body {
        font-size: 12px !important;
    }

    .p-sm-10 {
        padding: 10px;
    }

    .pt-sm-10 {
        padding-top: 10px;
    }

    .fs-sm-18 {
        font-size: 18px;
    }

    .fs-sm-12 {
        font-size: 12px !important;
    }

    .f-sm-end {
        display: flex;
        justify-content: flex-end;
    }

    .content-heading {
        justify-content: space-between !important;
    }

    .f-sm-r>button {
        float: right;
    }

    .pr-sm-unset {
        padding-right: unset !important;
    }

    .list-item-row {
        .card {
            margin-bottom: 15px !important;
        }
    }

    .spec-page-container {
        margin-top: 10px !important;
        margin-left: 5px !important;
        margin-right: 5px !important;
        border: unset !important;
    }

    .tab-content {
        padding: unset !important;
        padding-top: 10px !important;
    }

    .center-container {
        -webkit-align-items: unset !important;
        align-items: unset !important;
    }

    .projectTabs>.nav-item>.nav-link {
        //border-left: 1px solid   $gray;
        //background-color: #5BC0DE;
        padding: 9px 10px !important;
    }

    .memberTabs>.nav-item>.nav-link {
        padding: 9px 3px !important;
    }

    .content-wrapper {
        padding: 15px 10px 0px 10px !important;
    }

    .content-wrapper .content-heading {
        margin-bottom: 10px !important;
        //border-bottom: unset !important;
    }

    .badge {
        padding: 6px 1px !important;
    }

    .projectSubTabs>.nav-item>.nav-link {
        padding: 11px 7px !important;
        font-size: 10px !important;
    }

    .pt-05,
    .py-05 {
        padding-top: 5px !important;
    }

    .pt-5,
    .py-5 {
        padding-top: 15px !important;
    }

    .pt-10,
    .py-10 {
        padding-top: 10px !important;
    }

    .ai-sm-left {
        align-items: left !important;
    }

    .pl-sm-15 {
        padding-left: 15px !important;
        ;
    }

    .aside-container {
        position: fixed;
    }

    .sidebar-nav {
        height: 100%;
    }

    .sidebar {
        padding-bottom: unset !important;
        height: 100% !important;
    }

    .user-block {
        padding: 2px 10px 2px !important;
    }

    .user-menu>a>button {
        font-size: 12px !important;
    }

    .d-i-md {
        display: none !important;
    }

    .center-container .center-content {
        min-height: unset !important;
    }

    .okr-info-font-size {
        font-size: 9px !important;
    }



    // .overview-change-report-category-container 
}

@media screen and (max-width: 1224px) {
    .spec-page-container {
        margin-top: 10px !important;
        margin-left: 5px !important;
        margin-right: 5px !important;
    }

    .d-i-md {
        display: none !important;
    }

    .center-container .center-content {
        min-height: unset !important;
    }

    .okr-info-font-size {
        font-size: 11px !important;
    }
}

@media screen and (min-width: 768px) {
    .d-i-md {
        display: inline !important;
    }

    .okr-filter-popover {
        width: 500px;
    }

    .ai-md-center {
        align-items: center !important;
    }

    .m-t-md--19 {
        margin-top: -19;
    }

    .p-md-5 {
        padding: 5px;
    }

    .py-md-3 {
        padding-top: 3px;
    }

    .font-size-md-24 {
        font-size: 24px;
    }

    .font-size-md-20 {
        font-size: 20px;
    }

    .d-md-unset {
        display: unset !important
    }

    .okr-info-font-size {
        font-size: 11px !important;
    }
}

.inviteLinks {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    a {
        padding: 0;
        font-size: 12px;
        margin-inline-start: 8px;
    }
}

.react-checkbox-tree {
    font-size: 13px !important;
}

.progress {
    border-radius: 1.5rem;
    border: 0 !important;
    background-color: var(--placeholder);
}

.small-icon {
    flex: 0 0 auto;
    height: 16px;
    width: 16px;

    margin-inline-end: 5px;
}

.mini-icon {
    flex: 0 0 auto;
    height: 12px;
    width: 12px;

    margin-inline-end: 5px;
}

div.ReactTags__selected {
    padding: 0;
}

.bp4-popover2.bp4-popover2-content-sizing .bp4-popover2-content {
    max-width: none;
}

.bp4-popover2-content {
    font-size: 12px;
    border-radius: 10px !important;
}

.bp4-popover2-transition-container {
    z-index: 10000 !important;
}

.bp4-popover2.bp4-context-menu2-popover2 {
    box-shadow: none;
}


.auto-height {
    height: auto !important;
}

.height-31 {
    height: 31px !important;
}

hr.divider-short {
    margin: 8px 0;
}

.userDropDown {
    min-width: 220px;
}

.userDropDown>.bp4-popover2-content {
    padding: unset !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.unset-justify-content {
    justify-content: unset !important;
}

#react-select-2-placeholder {
    color: var(--border)
}

.react-datepicker__input-container>input,
.memberWorkDiaryDatePicker>div>input {
    background-image: url('../../icons/menu-icons/workDiary-icon.svg');
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: center;
    background-size: 19px 25px;
}

.react-datepicker__children-container {
    width: 13.8rem;
    margin: 0.4rem;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
    height: auto;
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
    .d-xxl-inline {
        display: inline !important;
    }

    .d-xxl-none {
        display: none !important;
    }
}

.warning-box {

    background-color: var(--warning-box-bg);
    color: black !important;
    padding: 10px;
    border: 1.2px solid var(--yellow-dark);
    border-radius: var(--menu-border-radius);
}

.time-download-notif-top-navbar {
    background-color: var(--time-download-notif-top-navbar-bg);
    height: var(--time-download-notif-top-navbar-height);
    color: var(--time-download-notif-top-navbar-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: var(--menu-border-radius)
}

.time-download-notif-top-navbar button {
    font-weight: 500;
    color: var(--time-download-notif-top-navbar-link-color)
}

.feedback-star-fill-icon,
.feedback-star-fill-icon path {
    color: var(--yellow);
    fill: var(--yellow);
    stroke: var(--yellow);
}

.bp4-timepicker .bp4-timepicker-input-row {
    border: 1px solid var(--light-gray2) !important;
    border-radius: 4px !important;
    box-shadow: none !important;
}

.scroll-hidden {
    overflow: hidden;
}

.slack-sso-login-message .title {
    text-align: center;
    position: relative;
    color: var(--dark-gray5);
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.16px;
}

.slack-sso-login-message .subtext {
    text-align: center;
    color: var(--dark-gray5);
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.12px;
}

.slack-sso-login-message path {
    fill: var(--dark-gray5);
}

.slack-connect-image {
    img {
        width: 66px;
        height: 66px;
        margin-left: 30px;
        margin-right: 30px;
    }
}

.sidebar-nav>li>a.back-to-list-menu {
    // margin-inline-start: 10px;

    // margin-top: 20px;
    // margin-bottom: 20px;

    padding-inline-start: 10px !important;
    color: var(--primary) !important;

    svg,
    path {
        fill: var(--primary) !important;
    }
}

.sidebar-nav .badge {
    display: inline-block;
}
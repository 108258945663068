/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.7.8
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */
//font
@import "/fonts/diamondsans/diamondsans.css";
@import "/fonts/inter/inter.css";

// Bootstrap
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
// Variables
@import "app/common/variables";
// Layout
@import "app/layout/layout";
@import "app/layout/layout-extra";
@import "app/layout/layout-animation";
@import "app/layout/top-navbar";
@import "app/layout/sidebar";
@import "app/layout/offsidebar";
@import "app/layout/user-block";
@import "app/layout/notifications";
@import "app/layout/settings";
@import "app/layout/top-navbar-links.scss";
// Common
@import "app/common/page-loader";
@import "app/common/typo";
@import "app/common/bootstrap-reset";
@import "app/common/bootstrap-custom";
@import "app/common/button-extra";
@import "app/common/placeholder";
@import "app/common/cards";
@import "app/common/circles";
@import "app/common/dropdown-extra";
@import "app/common/half-float";
@import "app/common/animate";
@import "app/common/slim-scroll";
@import "app/common/inputs";
@import "app/common/utils";
@import "app/common/print";
@import "app/common/ReactTags.scss";
@import "app/common/nav-tab.scss";
@import "app/common/TimeProgress.scss";
@import "app/common/recharts.scss";
//project
@import "app/systemCommonStyles.scss";
@import "app/materialStyles.css";
@import "app/spaces.css";
@import "app/buttons.css";
@import "app/navbar.scss";
@import "app/navbar-animated-menus.css";


//rtl
@import "app/layout/rtl-layout.scss";

@import "branding.css";
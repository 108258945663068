/* ========================================================================
     Component: typo
 ========================================================================== */

// @import url(//fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,400italic);
body {
    color: $body-color;
    font-size: 0.875rem; // 14px
}

h1,
h2,
h3,
h4 {
    font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.65625rem;
}

h1,
.h1 {
    font-size: 2.25rem; // 36px
}

h2,
.h2 {
    font-size: 1.875rem; // 30px
}

h3,
.h3 {
    font-size: 1.5rem; // 24px
}

h4,
.h4 {
    font-size: 1.125rem; // 18px
}

h5,
.h5 {
    font-size: .875rem;
}

h6,
.h6 {
    font-size: 1rem;
}

p {
    margin-bottom: .65rem; // ~10px
}
.navbar-links {
    transition: all 0.2s;
    padding: 10px;

    &:hover {
        background: #fafafa;
        color: #1e3a8a;
    }

    &.active {
        color: #facc15 !important;
    }
}

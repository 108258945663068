.timeprogress-time {
    font-weight: bold;
    font-size: 10px;
    color: black;
}

.timeprogress-label {
    font-size: 12px;
    color: #888888;
}

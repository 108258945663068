:root {
    --gray: #ABB3BF;
    --light-gray: #F6F7F9;

    --branding: #215DB0;
    --branding-darker: #184A90;
    --heading-height: 54px;

    --green: #32A467;
    --yellow: #FBB360;
    --red: #E76A6E;
    --placeholder: #DCE0E5;
    --warning-box-bg: #fbb36030;
    --dark-gray1: #1C2127;
    --dark-gray2: #252A31;
    --dark-gray3: #2F343C;
    --dark-gray4: #383E47;
    --dark-gray5: #404854;

    --green3: #238551;
    --green5: #72CA9B;

    --gold5: #FBD065;

    --gray1: #5F6B7C;
    --gray2: #738091;
    --gray3: #8F99A8;
    --gray4: #ABB3BF;
    --gray5: #C5CBD3;

    --light-gray1: #D3D8DE;
    --light-gray2: #DCE0E5;
    --light-gray3: #E5E8EB;
    --light-gray4: #EDEFF2;
    --light-gray5: #F6F7F9;

    --font-family-default-latin: 'Inter', sans-serif;
    --font-family-default-rt: DiamondSansX, sans-serif;
    --horizontal-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.05) inset;
    --horizontal-shadow-right-to-left: -2px 0px 2px 0px rgba(0, 0, 0, 0.05) inset;
    --menu-shadow: -2px 0px 2px 0px rgba(0, 0, 0, 0.15);
    --menu-shadow-right-to-left: 2px 0px 2px 0px rgba(0, 0, 0, 0.05);
    --menu-border-radius: 7px;
    --menu-border-radius-right-to-left: 7px;

    --body-bg: white;
    --active-bg: #EDEFF2;
    --border: var(--light-gray3);
    --app-menu-width: 62px;
    --app-menu-item-width: 52px;
    --app-menu-item-height: 52px;
    --app-menu-space-between-items: 10px;
    --app-menu-icon-size: 24px;
    --app-menu-label-display: inline;
    --app-menu-label-font-size: 11px;
    --app-menu-label-color: black;
    --app-menu-label-margin-top: 5px;
    --app-menu-padding: 0 5px 0 5px;
    --app-menu-bottom-item-width: 52px;
    --app-menu-bottom-item-height: 52px;

    --sidebar-width-with-app-menu: calc(var(--sidebar-width-without-app-menu) + var(--app-menu-width));
    --sidebar-width-without-app-menu: 220px;
    --sidebar-width-without-sub-menu: var(--app-menu-width);
    --sidebar-width: var(--sidebar-width-with-app-menu);
    --sidebar-width-negative: calc(-1 * var(--sidebar-width));
    --sidebar-bg: #EDEFF2;
    --sidebar-color: #404854;
    --sidebar-hover-color: #ffffff9c;
    --sidebar-active-color: var(--branding);
    --sidebar-active-bg: white;
    --sidebar-item-margin: 9px;
    --sidebar-item-margin-end: 12px;
    --sidebar-heading-color: #8F99A8;




    --navbar-brand-bg: transparent;
    --navbar-brand-color: white;
    --topnavbar-bg: var(--branding);
    --topnavbar-dropdown-toggle-bg: transparent;
    --topnavbar-dropdown-toggle-color: white;
    --organization-selector-color: var(--sidebar-color);
    --organization-selector-bg: transparent;
    --organization-selector-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
    --icons: #A1A1A1;
    --button-radius: 4px;
    --card-box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 20%);
    --card-box-shadow-hover: 0px 0px 3px 0px rgb(0 0 0 / 40%);
    --okr-selected-bg: var(--light-gray5);

    --primary: var(--branding);
    --primary-color: #ffffff;
    --primary-normal-bg: var(--primary);
    --primary-hover-bg: var(--branding-darker);
    --primary-active-bg: var(--branding-darker);
    --primary-disabled-bg: #E5E5E5;
    --primary-disabled-color: var(--gray);
    --primary-disabled-border: var(--border);

    --outline-primary-normal-bg: white;
    --outline-primary-hover-bg: #E7EFF3;
    --outline-primary-active-bg: #CFDEE8;
    --outline-primary-disabled-bg: var(--light-gray);
    --outline-primary-disabled-color: var(--border);

    --chart-color1: #147EB3;
    --chart-color2: #29A634;
    --chart-color3: #D1980B;
    --chart-color4: #D33D17;
    --chart-color5: #9D3F9D;
    --chart-color6: #00A396;
    --chart-color7: #DB2C6F;
    --chart-color8: #8EB125;
    --chart-color9: #946638;
    --chart-color10: #7961DB;

    --avatar-color1: #9D3F9D;
    --avatar-color2: #00A396;
    --avatar-color3: #DB2C6F;
    --avatar-color4: #8EB125;
    --avatar-color5: #946638;
    --avatar-color6: #7961DB;

    --btn-secondary-border: #eaeaea;

    --sidebar-backdrop-opacity: 0.5;
    --sidebar-backdrop-background-color: #FFFFFF;
    --content-wrapper-max-width: 1900px;


    --table-td-border-bottom: none;
    --table-tr-background-hover: none;
    --table-head-background-color: none;
    --table-head-color: var(--gray4);
    --table-head-border-bottom: 1px solid var(--gray4);

    --time-download-notif-top-navbar-height: 30px;
    --time-download-notif-top-navbar-bg: var(--yellow);
    --time-download-notif-top-navbar-color: var(--sidebar-color);
    --time-download-notif-top-navbar-link-color: var(--primary);

    --small-font-size: 12px;


    @media (max-width: 991.98px) {
        --small-font-size: 8px;
    }




}

.form-control:focus {
    border-color: var(--primary);
}
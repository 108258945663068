.nav-tabs {
    border-bottom: 1px $content-heading-border solid !important;
    height: 40px;
    width: 100%;
    padding: 0;
    font-size: 12px
}

.nav-tabs>.nav-item>.nav-link.active,
.nav-tabs>.nav-item>.nav-link.active:hover {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 3px solid var(--branding);
    color: var(--branding);
}

.nav-tabs>.nav-item>.nav-link {
    border: none;
    background-color: white;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    color: #737373;
    padding: 9px 17px !important;
    border-top-left-radius: 10px;
}

.tab-content {
    border: none;
}

.nav-item {
    border-radius: 6px 0px 0px 0px;
}

@media (max-width: 991.98px) {
    .nav {
        justify-content: space-around;
    }
}

.tab-content {
    padding: 10px 18px;
}
.invitations{
    width: 100%;
    td{
        vertical-align: middle !important;
    }
    .btn {
        width:35px;
        height:35px;
        border-radius: 50%;
        display: flex;
        align-items: center;
    justify-content: center;
    }
}
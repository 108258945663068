.right-to-left {

    .react-datepicker__input-container>input,
    .memberWorkDiaryDatePicker>div>input {
        background-position-x: 5%;
    }

    // .form-check-label input[type="radio"] + span {
    //     margin-inline-start: 20px;
    // }
    .fa-angle-right:before {
        content: "\f104" !important;
    }

    .fa-angle-left:before {
        content: "\f105" !important;
    }

    .fa-chevron-circle-right:before {
        content: "\f137" !important;
    }

    .fa-chevron-circle-left:before {
        content: "\f138" !important;
    }

    .aside-container {
        left: inherit;
        right: 0;
    }

    .wrapper .section-container {
        margin-left: 0;
    }

    @media only screen and (min-width: 1025px) {
        .sidebar:not(.show-scrollbar) {
            margin-right: inherit;
            margin-left: -17px;
            overflow-y: scroll;
        }
    }

    .sidebar-nav {
        padding-right: 0;
        padding-left: inherit;
    }

    .aside-container,
    .aside-inner {
        border-right: none;
    }

    .navbar-brand {
        margin-right: 0px;
    }

    // // Aside toggled layout
    // // On mobile acts like offcanvas
    // -----------------------------
    @include media-breakpoint-down(sm) {
        .navbar-brand {
            padding-top: 0;
            background-color: transparent;
            width: auto;
            border-right: none;
            border-left: none;
        }

        .wrapper {
            .aside-container {
                // margin-left: -$aside-wd;
                // margin-right: -$aside-wd;
                // margin-left: inherit;
                display: none;
            }

            .section-container {
                margin-right: 0;
                margin-left: inherit;
            }
        }
    }

    // // Aside toggled layout
    // // On mobile acts like offcanvas
    // // -----------------------------
    // @include media-breakpoint-down(sm) {
    //     .wrapper {
    //         .aside-container {
    //             // margin-left: -$aside-wd;
    //             margin-right: -$aside-wd;
    //             margin-left: inherit;
    //         }
    //         .section-container {
    //             margin-right: 0;
    //             margin-left: inherit;
    //         }
    //     }
    //     .aside-toggled {
    //         .wrapper {
    //             .section-container,
    //             .footer-container {
    //                 // margin-left: $aside-wd;
    //                 margin-right: $aside-wd;
    //                 margin-left: inherit;
    //             }

    //             .aside-container {
    //                 width: auto;
    //                 // margin-left: 0;
    //                 margin-right: 0;
    //                 margin-left: inherit;
    //                 .aside-inner {
    //                     width: auto;
    //                 }
    //             }
    //         }
    //     }

    //     .csstransforms3d {
    //         .wrapper {
    //             backface-visibility: hidden;
    //             .section-container,
    //             .footer-container {
    //                 // margin-left: 0;
    //                 margin-right: 0;
    //                 margin-left: inherit;
    //                 transform: translate3d(0, 0, 0);
    //                 transition: transform 0.3s ease;
    //             }
    //             .aside-container {
    //                 // margin-left: 0;
    //                 margin-right: 0;
    //                 margin-left: inherit;
    //                 transform: translate3d(-$aside-wd, 0, 0);
    //                 transition: transform 0.3s ease;
    //             }
    //         }

    //         .aside-toggled {
    //             .wrapper {
    //                 .section-container,
    //                 .footer-container {
    //                     transform: translate3d($aside-wd, 0, 0);
    //                 }
    //                 .aside-container {
    //                     transform: translate3d(0, 0, 0);
    //                 }
    //             }
    //         }
    //     }
    // }

    // // Toggle and collapsed behavior overrides
    // @include media-breakpoint-down(sm) {
    //     .aside-collapsed .wrapper {
    //         .aside-container {
    //             // margin-left: -$aside-wd-collapsed;
    //             margin-right: -$aside-wd-collapsed;
    //             margin-left: inherit;
    //         }
    //     }

    //     .aside-collapsed.aside-toggled {
    //         .wrapper {
    //             .section-container,
    //             .footer-container {
    //                 // margin-left: $aside-wd-collapsed;
    //                 margin-right: $aside-wd-collapsed;
    //             }

    //             .aside-container {
    //                 // margin-left: 0;
    //                 margin-right: 0;
    //                 margin-left: inherit;
    //             }
    //         }
    //     }

    //     .csstransforms3d {
    //         .aside-collapsed .wrapper {
    //             backface-visibility: hidden;
    //             .section-container,
    //             .footer-container {
    //                 // margin-left: 0;
    //                 margin-right: 0;
    //                 margin-left: inherit;
    //                 transform: translate3d(0, 0, 0);
    //                 transition: transform 0.3s ease;
    //             }
    //             .aside-container {
    //                 // margin-left: 0;
    //                 margin-right: 0;
    //                 margin-left: inherit;
    //                 transform: translate3d(-$aside-wd-collapsed, 0, 0);
    //                 transition: transform 0.3s ease;
    //             }
    //         }

    //         .aside-collapsed.aside-toggled {
    //             .wrapper {
    //                 .section-container,
    //                 .footer-container {
    //                     transform: translate3d($aside-wd-collapsed, 0, 0);
    //                 }
    //                 .aside-container {
    //                     transform: translate3d(0, 0, 0);
    //                 }
    //             }
    //         }
    //     }
    // }
}
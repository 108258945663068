.custom-tooltip {
    background-color: rgba(255, 255, 255, 0.726);
}
.table-overview{
    td {
        vertical-align: middle;
        div{
            display: flex;
        flex-direction: row;
        align-items: center;
        }
        
    }
}
.notification-hints{
    cursor:pointer
}

.notification-hints-counts {
    
     margin-inline-start: -15px !important;
     margin-top: -15px !important; 
     .badge{
        border: white solid 1.5px;
     }
}
/* Example Styles for React Tags*/
div.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
    width: auto;
    border-radius: 2px;
    display: inline-block;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    border: none;
    padding: 0 4px;
    padding-inline-start: 15px;
}

div.ReactTags__selected {
    border-radius: 5px;
    border: solid 1px #bebebe;
    padding-top: 5px;
    padding-bottom: 5px;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    border: 2px solid transparent;

    background: #f0f0f0;
    font-size: 10px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    cursor: move;
    border-radius: 5px;
}

div.ReactTags__selected .ReactTags__remove {
    color: #aaa;
    margin-inline-start: 5px;
    cursor: pointer;
    // display: inline-block;
    // border-radius: 500px;
    // margin: 0 0.5em;
    // background-color: #ddd;
    // vertical-align: baseline;
    // border: 2px solid transparent;
    // width: 11.7px;
    // height: 11.7px;
    // font-
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}

div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
}

div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    font-size: 10px;
    padding: 5px 0px;
    margin: 0;
}

div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}


.ReactTags__tagInputField {
    background-color: transparent;
}
.icon-bar {
    background-color: white;
}

.navbar-toggler {
    background: none;
    border: none;
}

.navbar-toggler:active,
.navbar-toggler:focus {
    outline: 0;
}

.navbar-toggler .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    margin: 4px 0 4px 0;
    transition: all 0.2s;
}

.navbar-dark .navbar-toggler .icon-bar {
    background: #ffffff;
}

.aside-toggled .icon-bar:nth-of-type(1) {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
}

.aside-toggled .icon-bar:nth-of-type(2) {
    opacity: 0;
    filter: alpha(opacity=0);
}

.aside-toggled .icon-bar:nth-of-type(3) {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
}


.icon-bar:nth-of-type(1) {
    transform: rotate(0);
}

.icon-bar:nth-of-type(2) {
    opacity: 1;
    filter: alpha(opacity=100);
}

.icon-bar:nth-of-type(3) {
    transform: rotate(0);
}
.MuiAccordion-root {
    flex-direction: 'row-reverse !important';
}

.MuiAccordionSummary-root {
    border-bottom: 1px solid var(--border) !important;
}

.MuiAccordionSummary-root.Mui-expanded {
    border-radius: 0 !important;
}

.MuiButtonBase-root {
    border-radius: 4px !important;
}

.MuiTablePagination-caption,
.MuiTablePagination-menuItem {
    font-family: var(--font-family-default-latin) !important;
    font-size: 12px !important;
}

.MuiMenuItem-root {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    font-size: 12px !important;
}

.MuiInput-underline:after {
    border-bottom: none !important;
}

.MuiTablePagination-selectRoot {
    margin-left: 2px !important;

}

.MuiTableCell-root {
    font-size: 12px !important;
    padding: 4px !important;

}

/* .MuiTableCell-root:not(.MuiTableCell-head) {
    border-bottom: none !important;
} */

/* .MuiTableRow-hover:hover td:nth-child(2) {
    text-decoration: underline;
} */

.MuiSvgIcon-root {
    height: 0.8em;
}

.MuiIconButton-root {
    padding: 4px !important;
}

.MuiSvgIcon-fontSizeSmall {
    font-size: 1.05rem !important;
}


.MuiAccordion-root {
    -webkit-box-shadow: 0 0 4px rgb(0 0 0 / 4%) !important;
    box-shadow: 0 0 4px rgb(0 0 0 / 4%) !important;
    border: 1px solid var(--border) !important;
}

.MuiAccordion-root:hover {
    -webkit-box-shadow: 0 0 6px #2F343C22 !important;
    box-shadow: 0 0 6px #2F343C22 !important;
}

.MuiInput-underline:before {
    border-bottom: none !important;
}

.MuiAccordionDetails-root {
    padding: 0 !important
}

.MuiTableRow-footer {
    display: flex !important;
    justify-content: center !important;
}

.underline-hover:hover {
    text-decoration: underline;
}

/* .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: var(--sidebar-bg);
} */

.MuiTableRow-root {
    height: 47px;
}

.MuiTableRow-footer,
.MuiTableRow-head {
    height: auto;
}
/* ========================================================================
     Component: user-block
 ========================================================================== */

// for use as navbar item
.has-user-block {
    display: block;
    overflow: hidden;
    border: 0 !important;
    // width: 100% !important;
}

.user-block {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 276px;
    position: relative;
    padding: 2px 0 2px;
    border-inline-start:2px solid #FFFFFF22;
    cursor: pointer;
    padding-inline-start: 10px;
    @include clearfix();

    > .user-block-picture {
        position: relative;
        width: 60px;
        margin: 0 auto;

        > img {
            max-width: 100%;
            height: auto;
        }

    }
    white-space: nowrap;

    .user-block-name, .user-block-role {
        display: inline-block !important;
        width: 146px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
    .user-block-role {
        font-size: 12px !important;
        background-color: transparent !important;
        font-weight: normal;
    }
}

.user-block-status {
    position: relative;

    > .circle {
        position: absolute;
        bottom: 0;
        right: 0;
        border: 2px solid #fff;
    }

}

.user-block-name {
    font-weight: 600;
    font-size: 14px;
}

.user-block-email {
    font-weight: 400;
    font-size: 12px;
}
.organization-block {
    text-align: center;
    background-color: var(--light-gray4);
    font-weight: normal;
    font-size: 12px;
    padding: 12px;
    margin: 6px -20px 16px;
}

.terms-block {
    text-align: center;
    font-weight: normal;
    font-size: 12px;
    padding: 8px;
    margin: 0 -20px -20px;
    color: var(--gray4);
}
.terms-block a {
    color: var(--gray4);
}
.terms-block a:hover {
    color: var(--branding);
}
// Adapt to collapsed mode
.aside-collapsed, .aside-collapsed-text {
    .user-block {
        padding: 15px 0 14px;
        margin: 0;
        text-align: center;

        > .user-block-picture {
            float: none;
            margin: 0  auto;
            width: 50px;

            > .user-block-status {
                display: block;
            }

        }

        .user-block-info {
            display: none;
        }

    }

}

.btn-sm svg {
    width: 20px;
    height: 20px;
}

.btn {
    border-radius: var(--button-radius) !important;
}



.btn:focus,
.btn.focus {
    box-shadow: none;
}

.btn-primary path {
    fill: var(--primary-color)
}

.btn-primary svg {
    margin-inline-end: 5px;
}


.btn-primary,
.btn-outline-primary {
    box-shadow: var(--horizontal-shadow-right-to-left);
}

.right-to-left .btn-primary,
.right-to-left .btn-outline-primary {
    box-shadow: var(--horizontal-shadow);
}

.btn-primary,
.btn-primary:focus {
    border: var(--primary-normal-bg);
}

.btn-primary:hover {
    border-color: var(--primary-hover-bg);
    background-color: var(--primary-hover-bg);
}

.btn-primary:active:not(.disabled) {
    border: var(--primary-active-bg) !important;
    background-color: var(--primary-active-bg) !important;
}

.btn-primary:disabled {
    background-color: var(--primary-disabled-bg);
    color: var(--primary-disabled-color);
    border-color: var(--primary-disabled-border);
}

.btn-outline-primary {
    background-color: var(--outline-primary-normal-bg);
    border-color: var(--branding) !important;
    color: var(--branding) !important;
}

.btn-outline-primary:hover {
    background-color: var(--outline-primary-hover-bg) !important;
}

.btn-outline-primary:active:not(.disabled) {
    background-color: var(--outline-primary-active-bg) !important;
}

.btn-outline-primary:disabled {
    color: var(--outline-primary-disabled-color) !important;
    border: none;
    box-shadow: none;
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: var(--outline-primary-disabled-color) !important;
    background-color: var(--outline-primary-disabled-bg) !important;
}

.btn-link {
    color: var(--primary);
    font-size: 14px;
}

.btn-link path {
    fill: var(--primary)
}

.btn-link:hover {
    text-decoration: none;
}

.btn-hover-light-primary:hover {
    color: #3699ff;
    background-color: #e1f0ff;
    border-color: transparent;
}


.btn-icon {
    padding: 0;
    border: none;
    font-size: 7px;
    background-color: none;
    padding: 3px 1px 3px 1px;
}

.btn-icon:hover,
.btn-icon:focus {
    background-color: none;
}



.btn-primary:disabled {
    border: 1px solid;
}

@media screen and (max-width: 767.98px) {
    .btn-primary svg {
        -webkit-margin-end: 0;
        margin-inline-end: 0;
    }
}